const components = {
    "prompt_ai": {
        "aiLicenses": () => import('./../pages/ai-licenses/index'),
        "aiLicensesUsages": () => import('./../pages/ai-licenses-usages/index'),
    },

    "ai_settings":{
        "usages": () => import('./../pages/admin/usages/index'),
        "licenses": () => import('./../pages/admin/licenses/index'),
        "apiKeys": () => import('./../pages/admin/api-keys/index'),
        "AiModels": () => import('./../pages/admin/ai-models/index'),
    }
};

export default components;
